import { useState } from "react";
import Accordion from "../UI/Accordion";
import classes from "./Lessons.module.css";
import DetailsButton from "../UI/DetailsButton";
import { Navigate, useNavigate } from "react-router-dom";
import ScheduleDetailsCard from "../UI/ScheduleDetailsCard";
import Overlay from "../UI/Overlay";
import InputField from "../UI/InputField";
import VerticalTabs from "../UI/VerticalTabs";
import CloseIcon from "../../icons/CloseIcon";
import DetailsTab from "../UI/DetailsTab";
import LearnersTab from "../UI/LeanersTab";
import FeacturesTab from "../UI/FeacturesTab";
import ResourcesTab from "../UI/ResourcesTab";
import CalendarComponent from "../Dashboard/Components/CalendarComponent/CalendarComponent";
import ModalCard from "../UI/ModalCard/ModalCard";
import { createLesson } from "../../services/lessons";
import { useClasses } from "../../context/ClassesContext";
import { Bounce, toast } from "react-toastify";
import { useLessons } from "../../context/LessonsContext";
import CalendarLessonDetails from "../CalendarLessonDetails/CalendarLessonDetails";
import SectionTitleContainer from "../UI/SectionTitleContainer/SectionTitleContainer";
import AboutLessonCard from "../UI/AboutLessonCard/AboutLessonCard";
import CardContainer from "../UI/CardContainer/CardContainer";
import SolidStarIcon from "../../icons/SolidStarIcon";
import { calcDuration, formatedDateTime } from "../../utilities/functions";
import LessonDetailsModal from "../LessonDetailsModal/LessonDetailsModal";

const cards = [
  {
    title: "Live lessons",
    number: 18,
  },
  {
    title: "Interactive lessons",
    number: 32,
  },
];

const resourcesData = [
  {
    title: "English Grammar Term 2",
  },
  {
    title: "English Grammar Term 3",
  },
];

const dropList = [
  {
    title: "Resources",
    updateList: [
      {
        grade: "9th Grade",
        desc: "NS & Tech: Matter and Materials",
        date: "Tuesday 16 Feb - 3PM - 4PM",
      },
      {
        grade: "9th Grade",
        desc: "NS & Tech: Matter and Materials",
        date: "Tuesday 16 Feb - 3PM - 4PM",
      },
    ],
  },
  {
    title: "Curriculum",
    updateList: [
      {
        grade: "9th Grade",
        desc: "NS & Tech: Matter and Materials",
        date: "Tuesday 16 Feb - 3PM - 4PM",
      },
      {
        grade: "9th Grade",
        desc: "NS & Tech: Matter and Materials",
        date: "Tuesday 16 Feb - 3PM - 4PM",
      },
    ],
  },
];

const newLessonStats = [
  {
    title: "Type",
    value: "_",
  },
  {
    title: "Duration",
    value: "_",
  },
  {
    title: "Scheduled",
    value: "_",
  },
];

const tabs = ["Details", "Learners", "Features", "Resources", "Curriculum"];

const Lessons = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [lessonDetails, setLesssonDetails] = useState({})
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const toggleLessonDetails = () => {
    console.log("OPEN DETAILS");
    setIsOpen(!isOpen);
  };

  const [lessonType, setLessonType] = useState("");
  const [lessonName, setLessonName] = useState("");
  const [subject, setSubject] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [isSending, setIsSending] = useState(false);

  const { getLessons } = useLessons();

  const [schedule, setSchedule] = useState({
    title: "Mathematical Literacy Term 1",
    desc: "Live lessons / Mathematics / Teacher3 / Tuesday, 16th Feb, 2024 10:21 PM",
    id: 1,
  });

  const submitHander = async () => {
    try {
      setIsSending(true);
      const response = await createLesson({
        lesson_type: lessonType,
        lesson_name: lessonName,
        subject,
        date_scheduled: scheduleDate,
        start_time: startTime,
        end_time: endTime,
        class: selectedClass,
      });

      

      toast.success("Lesson added", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        toastId: "session-expired",
      });

      await getLessons();

      setLessonType(null);
      setLessonName("");
      setSubject("");
      setScheduleDate("");
      setStartTime("");
      setEndTime("");
      setSelectedClass(null);

      console.log("LESSON DATA TO SEND", response)

      navigate(`create-lesson/${response.id}`)
    } catch (err) {
      toast.error(err.errors[0].message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        toastId: "session-expired",
      });
      console.log(err);
    }

    setIsSending(false);

    setShowOverlay(false);
  };

  return (
    <div className="row">
      <div
        className={`col-6 d-flex flex-column gap-4 ${classes.calendarWrapper}`}
      >
        <ScheduleDetailsCard
          schedule={schedule}
          showButton={true}
          buttonText="View details"
        />
        <CalendarComponent
          dashoard={true}
          toggleDetails={(event) => {setLesssonDetails(event); toggleLessonDetails()}}
        />
      </div>
      <div className={`col-6 ${classes.updateWrapper}`}>
        <div
          className={`mb-3 d-flex justify-content-center align-items-center ${classes.scheduleButton}`}
          onClick={() => setShowOverlay(true)}
          role="button"
        >
          Schedule new lesson
        </div>
        <div className="row m-0 gap-3 w-100 ">
          {cards.map((item, i) => (
            <div
              className={`col d-flex justify-content-between flex-column  ${classes.cardCatainer}`}
              key={i}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>{item.title}</div>
                <div>{item.number}</div>
              </div>
            </div>
          ))}
        </div>
        <Accordion dropList={dropList} />
      </div>
      {showOverlay && (
        <Overlay onClick={() => setShowOverlay(false)}>
          <ModalCard
            title="Schedule new lesson"
            onClose={() => setShowOverlay(false)}
          >
            {/* <div className="row m-0 gap-3 w-100 mt-4">
              {newLessonStats.map((item, i) => (
                <div
                  className={`col d-flex align-items-start justify-content-between flex-column  ${classes.newLessonStatsCard}`}
                  key={i}
                >
                  <div>{item.title}</div>
                  <div>{item.value}</div>
                </div>
              ))}
            </div> */}
            {/* <VerticalTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              className="col mt-4"
            /> */}
            <div className="mt-4">
              {activeTab === 0 && (
                <DetailsTab
                  selectedClass={selectedClass}
                  setSelectedClass={setSelectedClass}
                  lessonType={lessonType}
                  setLessonType={(value) => setLessonType(value)}
                  lessonName={lessonName}
                  setLessonName={(value) => setLessonName(value)}
                  subject={subject}
                  setSubject={(value) => setSubject(value)}
                  scheduleDate={scheduleDate}
                  setScheduleDate={(value) => setScheduleDate(value)}
                  startTime={startTime}
                  setStartTime={(value) => setStartTime(value)}
                  endTime={endTime}
                  setEndTime={(value) => setEndTime(value)}
                />
              )}
              {activeTab === 1 && <LearnersTab />}
              {activeTab === 2 && <FeacturesTab />}
              {activeTab === 3 && <ResourcesTab />}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div
                className={classes.cancelButton}
                onClick={() => setShowOverlay(false)}
                role="button"
              >
                Cancel
              </div>

              {isSending ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                <div
                  className={classes.saveButton}
                  role="button"
                  onClick={submitHander}
                >
                  Schedule lesson
                </div>
              )}
            </div>
          </ModalCard>
        </Overlay>
      )}

      <LessonDetailsModal isOpen={isOpen} toggleDetails={toggleLessonDetails} lessonDetails={lessonDetails}/>
    </div>
  );
};

export default Lessons;
